const InvoiceList = {
    template: `<div class="items-view">
                   <resetPasswordComponent></resetPasswordComponent>
                  <headerSectionComponent></headerSectionComponent>
                  <div class="section white">
                      <div class="container">
                          <template v-if="$store.getters.getAppState=='ready'">
                               <!--<registerComponent></registerComponent>-->
                               <loginComponent></loginComponent>
                               <div class="row">
                                  <div class="col m1 s12 left sticky-component items-category-tree">
                                      <homeCategorySectionComponent :only_icons_view=true></homeCategorySectionComponent>
                                  </div>                                 
                                  <div class="col m9 s12">
                                      <vue-table theme="materialize" ref="table" :options="options" :data="invoices"  :columns="['SerNr','OfficialSerNr','TransDate__formatted','Total__formatted','Saldo__formatted','Download']"  ></vue-table>
                                  </div>
                                  <div class="col m2 s12 sticky-component right">
                                    <userSectionComponent></userSectionComponent>
                                    <carComponent></carComponent>
                                  </div>
                              </div>
                          </template>
                          <template v-else>
                            <div class="container center section white" style="min-height: 60%;" >
                               <h3>{{tr('Page loading please wait')}}</h3>
                             </div>
                          </template>
                      </div>
                  </div>
                  <footerSectionComponent></footerSectionComponent>
              </div>`,
    data: function () {
        return {
            invoices: [],
        }
    },
    beforeMount: function () {
        console.log("before")
    },
    async mounted() {
        await this.loadInvoices()
    },
    computed: {
        options: function () {
            return {
                hides:['limit'],
                classes: {
                    Download: "download-button",
                },
                icons: {
                    Download: {icon: "fas fa-download", position: "after"}
                },
                texts: {
                    filter: "Numero ...",
                    limit: "Max. ",
                },
                headings: {
                    SerNr: "Numero",
                    OfficialSerNr: "Nro Oficial",
                    TransDate__formatted: "Fecha",
                    Total__formatted: "Total",
                    Saldo__formatted: "Saldo",
                    Download:"Descargar"
                },
                orderValue: {
                    TransDate__formatted: "TransDate"

                },
                sortable: {TransDate: "desc"},
                filterable: ["SerNr", "OfficialSerNr"],
                click: {

                    Download: function (value, row) {

                        window.open('/invoices/getDocument?invoiceSerNr=' + row.SerNr)
                    }

                }

            }

        }
    },
    methods: {

        loadInvoices: function () {
            console.log('exec load')
            let self = this;
            $.ajax({url: '/invoices/getInvoices?jsonFormatted=1'})
                .done(function (response) {
                    console.log(response, "resp")
                    self.error = false;
                    self.invoices = _.map(response.invoices, (x) => {
                        return x.fields
                    })

                }).fail(function (error) {
                console.log(error, "e")
                self.error = true;
            })

        }
    }
}

Vue.component('InvoiceListComponent', InvoiceList)
